@font-face {
  font-family: 'Poppins-Medium';
  src: url('./fonts/Poppins-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('./fonts/Poppins-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('./fonts/Poppins-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('./fonts/Poppins-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('./fonts/Poppins-SemiBold.ttf');
  font-weight: 600;
}


/* Page layout and global styles */
body {
  font-family: 'Poppins-Medium';
  color: #002f54;
  min-height: 100vh;
  margin: 0;
}

#app {
  min-height: 100%;
}


h1, h2 {
  font-family: 'Poppins-Bold';
}

h3 {
  font-family: 'Poppins-Regular';
}

h4, h5, h6 {
  font-family: 'Poppins-Medium';
}

.fw-light {
  font-family: 'Poppins-Light';
  font-weight: 300;
}

.fw-bold {
  font-family: 'Poppins-Bold';
  font-weight: 700;
}

p {
  letter-spacing: 0.04em;
}

.extra-text-spacing {
  letter-spacing: 0.1em;
}

.btn-yellow {
  background: #ffe800;
  color: black;
  font-family: 'Poppins-SemiBold';
  padding: 0.5em 2em;

  @media (max-width: 1024px) {
    padding: 0.5em 0.7em;
  }
}

.btn-yellow:hover {
  background: rgba(255,232,0, 0.8);
  color: rgba(0,0,0, 0.8);
}

.btn-navy-blue {
  background: #002f54;
  color: white;
}

.btn-navy-blue:hover {
  border: 1px solid #002f54;
  color: #002f54;
}

.border-yellow {
  border: 1px solid #ffe800;
}
.border-navy {
  border: 1px solid #002f54;
}
.border-white {
  border: 1px solid #ffffff;
}

#page-layout {
  display: grid;
  min-height: 100vh;
  grid-template-columns: 80px 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas: "sidebar content" "sidebar footer";
}

#page-layout-with-sidebar {
  display: grid;
  /* min-height: 100vh; */
  height: 100vh;
  grid-template-columns: 80px 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas: "sidebar section-with-sidebar" "sidebar footer";
}


.sticky-navbar-wrapper {
  position: sticky;
  top: 0;
  z-index: 1000; 
  overflow: visible;
}

.sticky-navbar {
  display: flex;
  gap: 0em;
  background-color: white;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.sticky-navbar a {
  padding: .5rem;
  text-decoration: none;
}

.sticky-navbar a:hover {
  background: #ffe80066;
}

.sticky-navbar a.active {
  border-bottom: 3px solid #ffe800;
}





#content {
  grid-area: content;
}

.content-utility {
  grid-area: content;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
              url("../static/img/world-map.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
  margin-bottom: 2em;
}

#section-with-sidebar {
  background: #e6ecf4;
  grid-area: section-with-sidebar;
}

section#content-sidebar {
  overflow: auto;
  /* min-height: 92vh; */
  height: 100vh;
  min-width: max(200px,20vw);
}

#footer-container {
  grid-area: footer;
}

.text-yellow {
  color: #ffe800;
}

.text-navy-blue {
  color: #002f54;
}

.text-light-blue {
  color: #e6ecf4;
}

.bg-navy-blue {
  background: #002f54;
  color: white;
}

.bg-navy-blue-light {
  background: #e6ecf4;
}

.bg-yellow {
  background: #ffe800;
}

.bg-light-blue-50 {
  background: rgba(0, 178, 255, 0.2);
  color: rgb(0, 178, 255);
}

#section-with-sidebar {
  display: grid;
  grid-template-columns: 1fr 4fr;
  min-height: 92vh;
}

#content-mainpage {
  background: white;
  padding-left: 2rem;
  padding-right: 1rem;
  padding-top: 1rem;
  display: grid;
  grid-template-rows: min-content min-content min-content auto min-content;
  grid-template-columns: 100%;
}

.list-decoration-none {
  list-style: none;
  padding: 0;
  margin: 0;
}

.inner-nav-items {
  padding: 0.5em 1.5em;
  font-size: 0.9em;

}

.inner-nav-items:hover {
  background: #002f55;
  color: #ffe80066;
}

.inner-nav-items.active {
  background: #002f55;
  color: #ffe800;
}

#grid {
  margin-top: 2em;
}

#grid table {
    font-size: .85rem;
    line-height: 2rem;
    color: #000;
}

#grid thead {
    background: rgb(249,249,249);
    font-weight: 700;
}
#grid tbody {
    font-weight: 400;
}
#grid td, #grid th {
    border: 1px solid rgb(221,221,221);
    padding-left: .5rem;
    padding-right: .5rem;
    white-space: nowrap;
}
#grid tr:hover {
    background: rgb(245,245,245);
}
    

#graph {
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
}

/* Side bar  */

#sidebar {
  grid-area: sidebar;
  background: white;
  height: 100%;
  text-align: center;
  padding-top: 3em;
  position: fixed;
  width: 80px;
}

.nav-items {
  padding: 1em 0;
}

.nav-items:hover {
  background: #ffe80066;
}

.nav-items.active {
  background: #ffe800;
}

/* Home page */

#hero {
  padding-bottom: 6em;
  background-image: url(../static/img/Background-UpBeat-Project-1200x600.png);
  background-size: cover;
}

.landing-wb-logo {
  height: 2em;
}

.home-box-card-links {
  text-decoration: none;
  color: black;
}

.stats-icon {
  height: 4.5em;
}

.other-icons {
  height: 2em;
}

.btn-icons {
  height: 1.5em;
}

.others {
  height: 3em;
}

#stats {
  margin-top: -4em;
}

.clickable-box-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: white;
  padding: 2em;
  border-radius: 0.5em;
  display: grid;
  align-items: center;
}

.clickable-container {
  width: 200px;
  margin: 0 auto;
}

.box-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: white;
  padding: 2em;
  border-radius: 0.5em;
}

.box-card-alt {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 2em;
  border-radius: 0.5em;
}

.home-graph:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: 0.2s ease-in-out;
}
.home-graph p {
    font-size: .9rem;
    letter-spacing: 0px;
    max-width: 160px;
    margin: auto;
}

#explore-utilities-section {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1em;
  grid-template-areas:
    "explore-utility stats-country"
    "explore-utility stats-utility"
    "explore-utility stats-indicator"
    "explore-utility stats-year";

  @media (max-width: 767px) {
    /* Mobile view: 1 column for all */
    grid-template-columns: 1fr;
    grid-template-areas:
      "explore-utility"
      "news"
      "about";
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    /* Tablet view: 1 column for "explore-utility", 2 columns for "news about" on one row */
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "explore-utility explore-utility"
      "news about";
  }
}

#explore-utilities {
  grid-area: explore-utility;
}

#news {
  grid-area: news;
}

#about {
  grid-area: about;
}

.container-alt {
  padding: 0 3em;
}

.bottom-logos {
  padding: 2em 3em;
  text-align: center;
}

.bottom-logo {
  height: 3em;
  margin-right: 2em;
}

/* Indicator page  */
.indicator-icons {
  font-size: 1.4em;
}

.collapsed-nav {
  border-top: 1px solid rgba(0, 47, 84, 0.2);
  padding: 1em 0;
}

.collapsed-nav:first-of-type {
  border-top: none;
}

.toggle-boxblot {
  position: absolute;
  right: 0;
  margin-right: 1em;
  z-index: 1;
}

/* Utility page  */
.region-title {
  margin-top: 2em;
}

.country-flag-section {
display: flex;
margin-top: 1.5em;
padding: 0.6em 2em;
margin-bottom: 1em;
}

.country-utilities-text {
  font-size: 1.1em;
  cursor: pointer;
}

.flags {
  cursor: pointer;
}

.utility-ul {
  padding: 0.4em 2em;
  list-style: none;
  margin: 0;
  margin-bottom: 1em
}

.utility-list {
  margin-bottom: .5em;
  padding-left: 0;
}

/* Dashboard */
#content.utility-content-section{
  padding: 0 3em;
  overflow: unset;
}

.utility-table {
  width: 100%;
  margin-bottom: 1em;
  border-collapse: collapse;
  vertical-align: top;
}

.utility-table th,
.utility-table td {
  padding: 0.5em 0 0.5em 1em;
  text-align: left;
  font-size: 0.9em;
}

.utility-table th {
  background-color: #002f54;
  color: white;
  font-family: 'Poppins-Bold';
}

.utility-table td {
  font-family: 'Poppins-Light';
  color: black;
}

.utility-table tbody th {
  background-color: #002f54;
  color: white;
}

.utility-table tbody tr:nth-child(even) {
  background-color: #e6ecf4;
}

.utility-graph-section {
  border-top: 1px solid #e6ecf4;
}

.col-with-br {
  padding: 0 2em 0 0;
  border-right: 1px solid #e6ecf4;
}

.col-with-bl {
  padding: 0 0 2em 2em;
  border-left: 1px solid #e6ecf4;
}

.dashboard-subtitles{
  padding: 0;
  margin: 1em 0;
}

/* About */
.about-section h1 {
  font-size: 1.5em;
  margin-bottom: 1em;
}

.about-section p {
  font-size: 0.9em;
  font-family: 'Poppins-Light';
  font-weight: 300;
  letter-spacing: 0;
  
}

.about-section table {
  border: 1px solid #002f54;
  margin-bottom: 2em;
}

.about-section th,
.about-section td {
  padding: 0.5em 0 0.5em 1em;
  text-align: left;
  /* border-bottom: 1px solid #ddd; */
  font-size: 0.9em;
}

.about-section th {
  background-color: #002f54;
  color: white;
  font-family: 'Poppins-Bold';
  font-weight: 700;
}

.about-section td {
  font-family: 'Poppins-Light';
  font-weight: 300;
  color: black;
}

.about-section td strong {
  font-family: 'Poppins-Regular';
  font-weight: 400;
}

.about-section tbody th {
  background-color: #002f54;
  color: white;
}

.about-section tbody tr:nth-child(even) {
  background-color: #e6ecf4;
}

.about-section u {
  font-size: 1.1em;
  font-family: 'Poppins-Bold';
}

.about-section p em strong {
  font-size: 1.1em;
  font-family: 'Poppins-Bold';
}

.visx-tooltip strong {
  font-family: 'Poppins-SemiBold';
  font-weight: 600;
}

.t_tooltip td {
  padding-right: 1rem;
}


/* Footer */
#footer-container {
  padding-top: 1em;
}

.perf-graph {
  height: 100%;
}

.dash-graph {
  max-height: 450px;
  height: 450px;

}

.scrollTarget {
  scroll-margin-top: 5rem;
}

@media print {
  body {
    font-size: 10px;
  }
  
  #page-layout {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas: "content" "footer";
  }
  
  #page-layout-with-sidebar {
    display: block;
  }

  #section-with-sidebar {
    display: block;
  }

  #page-layout-with-sidebar article.w-75 {
    width: 100% !important;
  }
  
  #sidebar, #content-sidebar {
    display: none;
  }

  .btn {
    display: none;
  }

  aside {
    display: none;
  }

  /* yes, really */
  .toggle-boxblot {
    display: none;
  }

  h5 {
    break-after: avoid;
  }

  .col-with-bl, .col-with-br, .utility-graph-section {
    border: none;
    padding: 0;
  }

  .utility-table {
    -webkit-print-color-adjust:exact;
  }

  .dash-graph {
    width: 45vw;
    max-height: 35vh;
  }
  .dash-graph svg {
    transform: scale(0.45) translate(-55vw, -25vh);
  }
}
